.dropdown-network-wrapper {
    @apply relative z-10 flex items-center justify-between h-9 cursor-pointer px-[13px] py-1 rounded-[20px] bg-white dark:bg-black;
    background: linear-gradient(264.85deg, #43b649 30.84%, #005f33 93.78%);
  }
  .dropdown-network-wrapper img.arrow {
    @apply ml-3;
  }
  .dropdown-network-wrapper .network-title {
    @apply font-bold text-xs tracking-[-0.011em] text-white dark:text-black;
  }
  .dropdown-network-wrapper .network-title > div {
    @apply flex items-center dark:text-white;
  }
  .dropdown-network-wrapper .network-title img {
    @apply w-[15px] h-[15px] mr-1.5;
  }
  .dropdown-network-wrapper .bg {
    @apply w-screen h-screen fixed w-screen h-screen left-0 top-0;
  }
  .dropdown-network-wrapper .network-list {
    @apply absolute left-[-25px] z-[100] w-[193px] bg-white shadow-[0px_0px_4px_0px_#00000040] rounded-lg top-[46px] dark:bg-black;
    filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.2));
  }
  .dropdown-network-wrapper .network-list button {
    @apply flex items-center gap-1 w-full h-[52px] font-normal text-xs cursor-pointer pl-[11px] pr-2.5 py-0 hover:text-white first:rounded-[8px_8px_0_0] last:rounded-[0_0_8px_8px] dark:text-white;
    background: transparent;
  }
  .dropdown-network-wrapper .network-list button:hover {
    background: linear-gradient(96.31deg, #005f33 12.72%, #37a645 74.26%);
  }
  .dropdown-network-wrapper .network-list button img.chain-logo {
    @apply w-[25px];
  }
  .dropdown-network-wrapper .bg {
    background: rgba(0, 0, 0, 0.6);
  }
  