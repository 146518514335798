.warning-modal {
  @apply absolute -translate-x-2/4 -translate-y-2/4 max-w-[500px] w-[calc(100%_-_36px)] shadow-[0px_20px_24px_-4px_rgba(16,24,40,0.6),0px_8px_8px_-4px_rgba(16,24,40,0.04)] rounded-xl left-2/4 top-2/4;
}
.warning-modal .modal-body {
  @apply bg-white shadow-[0px_20px_24px_-4px_rgba(16,24,40,0.6),0px_8px_8px_-4px_rgba(16,24,40,0.04)] flex flex-col items-center justify-center text-center pt-5 pb-6 px-3.5 rounded-xl;
}
.warning-modal .modal-body .title {
  @apply font-bold text-2xl leading-6 text-[#EF3429] mt-4;
  font-family: "Inter" !important;
}
.warning-modal .modal-body .info {
  @apply font-normal text-2xl leading-[30px] text-[#EF3429];
  font-family: "Inter" !important;
}
.warning-modal .modal-body .des {
  @apply font-normal text-sm mt-5;
}
