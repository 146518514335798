@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  color: #000;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  width: 0px;
}

body::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 8px;
}

body::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 50px;
  cursor: pointer;
}

body::-webkit-scrollbar-thumb:hover {
  background: transparent;
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
  font-family: 'Nunito Sans' !important;
  font-style: normal;
  margin: 0;
}

button {
  border: none;
}

.ds-flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.scrollbar::-webkit-scrollbar {
  width: 10px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: #ffffff2e;
  border-radius: 100px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #00ff3e;
  border-radius: 100px;
}