.activity-filter {
  @apply flex items-center justify-evenly;
}
.activity-filter .filter {
  @apply font-semibold text-[10px] flex items-center justify-center text-white px-[10.23px] py-[3.78px] rounded-[20px];
  background: #dfe2e4;
}
.activity-filter .saved.actives {
  @apply text-black;
  background: #ffdd28;
}
.activity-filter .protected.actives {
  @apply text-[#EDF0F4];
  background: #43b649;
}
.activity-filter .incomplete.actives {
  @apply font-bold text-[#EDF0F4];
  background: #ef3429;
}
.activity-filter .canceled.actives {
  @apply text-black border border-solid border-black;
  background: #ffffff;
}
.activity-filter .all.actives {
  @apply font-normal text-white;
  background: #202024;
}
.activity-filter .active {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
