.login-body {
  @apply w-[416px] h-[600px] mx-auto mt-[203px] shadow-2xl shadow-black;
  background: linear-gradient(180deg, #e0e0e0 0%, #ffffff 100%);
}
.login-body .login-form {
  @apply flex flex-col justify-center items-center w-full max-w-[355px];
}
.login-body .login-form img.logo {
  @apply w-full max-w-[375px];
}
.login-body .login-form .btn-login {
  @apply h-[54px] w-[327px] flex justify-center items-center shadow-[0px_5px_5px_rgba(0,0,0,0.35)] font-bold text-[17px] leading-[26px] text-[#E4E4F0] px-2.5 py-3.5 rounded-xl border-[none];
  background: linear-gradient(262.9deg, #0f351c 3.74%, #018931 77.56%);
}
