.token-transfer-modal {
    @apply flex flex-col dark:bg-black dark:text-white;
  }
  .token-transfer-modal .modal-header {
    @apply flex flex-col items-start px-4 py-5;
  }
  .token-transfer-modal .modal-header h3 {
    @apply font-semibold text-base text-[#101828] dark:text-white;
    font-family: "Inter" !important;
  }
  .token-transfer-modal .modal-header p {
    @apply font-normal text-sm text-[#475467] mb-0 dark:text-white;
    font-family: "Inter" !important;
  }
  .token-transfer-modal .modal-body {
    @apply flex flex-col gap-4 pt-2 pb-6 px-4;
  }
  .token-transfer-modal .modal-body .error-message {
    @apply font-bold text-[10px] leading-[14px] text-[#FD0B0B] mt-2.5;
  }
  .token-transfer-modal .modal-body .token-max {
    @apply relative;
  }
  .token-transfer-modal .modal-body .token-max h3 {
    @apply font-bold text-[13px] text-[#344054] dark:text-white;
  }
  .token-transfer-modal .modal-body .btn-max {
    @apply absolute w-[50px] h-6 text-white font-medium text-sm rounded-2xl right-[13px] top-[26px];
    background: #43b649;
    font-family: "Inter" !important;
  }
  .token-transfer-modal .modal-body .token-amount {
    @apply w-full h-11 border shadow-[0px_1px_2px_rgba(16,24,40,0.05)] px-3.5 py-0 rounded-3xl border-solid border-[#D0D5DD] dark:bg-black dark:text-white;
  }
  .token-transfer-modal .modal-body .cex-select-wrapper h3 {
    @apply font-bold text-[13px] text-[#344054] mb-0 dark:text-white;
  }
  .token-transfer-modal .modal-body .cex-select-wrapper .yes-no {
    @apply h-5 mt-4;
  }
  .token-transfer-modal .modal-body .cex-select-wrapper .cex-select {
    @apply mt-4;
  }
  .token-transfer-modal .modal-body .cex-select-wrapper .cex-select h3 {
    @apply mb-1.5;
  }
  .token-transfer-modal .modal-body .cex-select-wrapper .wallet-select {
    @apply mt-4;
  }
  .token-transfer-modal .modal-body .cex-select-wrapper .wallet-select h3 {
    @apply mb-1.5;
  }
  .token-transfer-modal .modal-body .receiver-wrapper h3 {
    @apply font-bold text-[13px] text-[#344054] dark:text-white;
  }
  .token-transfer-modal .modal-body .receiver-wrapper .receiver {
    @apply flex items-center h-11;
  }
  .token-transfer-modal .modal-body .receiver-wrapper .receiver p {
    @apply w-[26px] h-full border mb-0 rounded-[8px_0_0_8px] border-r-[none] border-solid border-[#D0D5DD];
  }
  .token-transfer-modal .modal-body .receiver-wrapper .receiver input {
    @apply border h-11 font-normal text-base text-[#667085] rounded-[0_8px_8px_0] dark:bg-black dark:text-white;
    font-family: "Inter" !important;
  }
  .token-transfer-modal .modal-footer {
    @apply pt-[19px] pb-3.5 px-[19px];
  }
  .token-transfer-modal .modal-footer button {
    @apply w-full h-[33px] rounded-[999px] flex justify-center bg-white hover:bg-black dark:hover:border dark:hover:border-white dark:hover:shadow-[0px_0px_4px_3px_rgb(128,128,128)];
    filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.4));
  }
  .token-transfer-modal .modal-footer button img.presendLogo {
    @apply max-w-[40%] max-h-full;
  }
  