.activity-switch {
  @apply min-w-[130.85px] max-w-[130.85px] min-h-[23px] max-h-[23px] flex items-center rounded-[20px] bg-[#212125] dark:bg-white;
}
.activity-switch button {
  @apply min-h-[22.85px] max-h-[22.85px] font-extralight text-xs text-white flex items-center transition-[0.2s] px-[12.13px] py-[7.27px] rounded-[20px] bg-[#212125] dark:bg-white dark:text-black;
}
.activity-switch .active {
  @apply font-extrabold;
  background: linear-gradient(98.1deg, #005f33 -31.24%, #43b649 83.81%);
}
.activity-switch .false {
  @apply bg-[#212125] dark:bg-white;
}
