.token-select {
    @apply relative z-10 w-full bg-transparent p-0;
  }
  .token-select .token-title {
    @apply w-full h-[46px] flex items-center justify-between border shadow-[0px_1px_2px_rgba(16,24,40,0.05)] font-bold text-base px-5 py-0 rounded-3xl border-solid border-[#D0D5DD] dark:bg-black;
    background: #ffffff;
    font-family: "Inter" !important;
  }
  .token-select .token-title .token {
    @apply flex items-center text-black dark:text-white;
  }
  .token-select .token-title .token img.symbol {
    @apply w-[26px] h-[26px];
  }
  .token-select .token-title .token i {
    @apply w-[26px] h-[26px] rounded-[50%];
  }
  .token-select .token-title .token .name {
    @apply ml-2;
    font-family: "Inter" !important;
  }
  .token-select .token-title .token .price {
    @apply ml-4;
  }
  .token-select .token-title .arrow {
    @apply text-[17px] text-[#9D9D9D];
  }
  .token-select .assets-wrap {
    @apply absolute w-[calc(100%_-_30px)] max-h-[252px] border overflow-y-auto rounded-lg border-solid border-[#AEAEAE] left-[17px] top-[50px];
  }
  .token-select .assets-wrap::-webkit-scrollbar {
    @apply w-[5px];
  }
  .token-select .assets-wrap::-webkit-scrollbar-track {
    @apply rounded-lg;
    background: #fff;
  }
  .token-select .assets-wrap::-webkit-scrollbar-thumb {
    @apply cursor-pointer rounded-[50px] hover:cursor-pointer;
    background: #43b649;
  }
  .token-select .assets-wrap::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
  .token-select .assets-wrap ul.assets-list {
    list-style: none;
  }
  .token-select .assets-wrap ul.assets-list li button {
    @apply relative flex items-center w-full cursor-pointer p-[13px] border-b-[#D9D9D9D9] border-[none] border-b border-solid hover:text-white hover:bg-white dark:bg-black dark:text-white;
    background: #fff;
    outline: none;
  }
  .token-select .assets-wrap ul.assets-list li button:hover {
    background: linear-gradient(
      96.31deg,
      #005f33 12.72%,
      #37a645 77.6%
    ) !important;
  }
  .token-select .assets-wrap ul.assets-list li button img {
    @apply w-[26px];
  }
  .token-select .assets-wrap ul.assets-list li button i {
    @apply block w-[26px] h-[26px] rounded-[50%];
  }
  .token-select .assets-wrap ul.assets-list li button span {
    @apply font-semibold mx-[15px] my-0;
  }
  .token-select .assets-wrap ul.assets-list li button img.detail-icon {
    @apply absolute right-[21px];
  }
  