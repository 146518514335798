.dashboard {
  @apply mt-[163px] w-[387px] bg-white mx-auto my-0 dark:bg-black shadow-2xl shadow-black;
}
.dashboard .dashboard-body {
  @apply px-[18px] py-0;
}
.dashboard .dashboard-body .header {
  @apply ml-[-18px] z-[100] flex justify-between items-center w-full w-[387px] pt-2 h-14 pb-2 px-4 rounded-b-xl bg-black dark:bg-white;
}
.dashboard .dashboard-body .header .logo {
  @apply w-[114px] h-[33px];
}
.dashboard .dashboard-body .header .signout {
  @apply flex justify-center items-center w-[66px] h-[18px] font-bold text-[10px] tracking-[-0.006em] text-white rounded-xl dark:text-black;
  background: #ef3429;
}
.dashboard .dashboard-body .main-content {
  @apply relative;
}
.dashboard .dashboard-body .main-content .dark-mode {
  @apply mt-5 mb-[15px] mx-0;
}
.dashboard .dashboard-body .main-content .account-wrapper {
  @apply h-11 shadow-[0px_0px_5px_rgba(0,0,0,0.3)] flex items-center justify-between rounded-lg dark:text-white dark:bg-[#272727];
}
.dashboard .dashboard-body .main-content .account-wrapper h3 {
  @apply font-bold text-2xl ml-[27px] mb-0;
}
.dashboard .dashboard-body .main-content .account-wrapper > div {
  @apply flex items-center gap-0.5;
}
.dashboard .dashboard-body .main-content .account-wrapper > div div {
  @apply font-bold text-sm tracking-[0.5px];
}
.dashboard .dashboard-body .main-content .account-wrapper > div button {
  @apply mr-2;
  background: transparent;
}
.dashboard .dashboard-body .main-content .account-wrapper > div button img {
  @apply w-3.5 h-3.5;
}
.dashboard .dashboard-body .main-content .account-wrapper > div .tooltiptext {
  @apply w-[76px] text-center relative z-[1] ml-[-75px] transition-[0.3s] shadow-[3px_3px_3px_#ccc] p-[5px] rounded-md -right-5 top-[35px];
  background: #ffd24b;
}
.dashboard
  .dashboard-body
  .main-content
  .account-wrapper
  > div
  .tooltiptext::after {
  @apply content-[""] absolute ml-[-5px] border-b-[#ffd24b] border-8 border-solid border-transparent right-7 -top-3.5;
}
.dashboard .dashboard-body .main-content .main-token-wrapper {
  @apply relative h-[199px] z-[9] mt-16 mb-[18px] mx-0 pt-[67px] pb-[18px] px-6 rounded-2xl;
  background: linear-gradient(100.99deg, #0c4b22 6.19%, #00b43f 95.8%);
}
.dashboard .dashboard-body .main-content .main-token-wrapper .coin-logo {
  @apply absolute top-[-60px] translate-x-[-60px] left-2/4;
}
.dashboard .dashboard-body .main-content .main-token-wrapper .coin-logo img.bg {
  @apply w-[118px] h-[118px];
}
.dashboard
  .dashboard-body
  .main-content
  .main-token-wrapper
  .coin-logo
  img.icon {
  @apply w-[74px] h-[74px] absolute left-[21px] top-[25px];
}
.dashboard .dashboard-body .main-content .main-token-wrapper .toke-balance {
  @apply font-black text-[34px] leading-7 text-center tracking-[0.441404px] text-white;
}
.dashboard .dashboard-body .main-content .main-token-wrapper .usd-price {
  @apply font-semibold text-center text-[14.1249px] leading-7 tracking-[0.441404px] text-white;
}
.dashboard .dashboard-body .main-content .main-token-wrapper button {
  @apply w-full mt-7 rounded-[999px] flex justify-center bg-white hover:bg-black dark:bg-black dark:hover:bg-white;
}
.dashboard
  .dashboard-body
  .main-content
  .main-token-wrapper
  button
  img.presendLogo {
  @apply max-w-[40%] max-h-full;
}
.dashboard .dashboard-body .main-content .box-shadow {
  @apply absolute left-[-7px] w-[calc(105%)] h-[200px] blur-[20px] z-[1] top-[158px];
  background: linear-gradient(0deg, #43b649 -13.97%, #005f33 111.2%);
}
.dashboard .dashboard-body .main-content .assets-activity-wrapper {
  @apply relative;
}
.dashboard .dashboard-body .main-content .assets-activity-wrapper .divider {
  @apply w-full h-px bg-[#DFE2E4] absolute top-[43px];
}
.dashboard .dashboard-body .main-content .assets-activity-wrapper .MuiBox-root {
  @apply border-b-[none];
}
.dashboard .dashboard-body .main-content .assets-wrapper {
  @apply flex flex-col gap-2 mt-[11px] mb-5 mx-0;
}
.dashboard .dashboard-body .main-content .assets-wrapper button.asset {
  @apply w-full h-[72px] flex justify-between shadow-[0px_2px_4px_rgba(0,0,0,0.075)] dark:shadow-[0px_2px_4px_rgb(128,128,128)] hover:dark:shadow-[0px_3px_7px_3px_rgb(128,128,128)] pl-3 pr-[22px] py-4 rounded-lg dark:bg-[#1B1B1B] dark:text-white;
  background: #ffffff;
}
.dashboard .dashboard-body .main-content .assets-wrapper button.asset:hover {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
}
.dashboard .dashboard-body .main-content .assets-wrapper button.asset .token {
  @apply flex justify-between items-center gap-[21px];
}
.dashboard
  .dashboard-body
  .main-content
  .assets-wrapper
  button.asset
  .token
  .icon {
  @apply w-10 h-10;
}
.dashboard
  .dashboard-body
  .main-content
  .assets-wrapper
  button.asset
  .token
  .icon
  img {
  @apply w-full;
}
.dashboard
  .dashboard-body
  .main-content
  .assets-wrapper
  button.asset
  .token
  .icon
  p {
  @apply w-full h-full rounded-[50%];
}
.dashboard
  .dashboard-body
  .main-content
  .assets-wrapper
  button.asset
  .token
  .name-symbol {
  @apply flex flex-col items-start gap-[3px];
}
.dashboard
  .dashboard-body
  .main-content
  .assets-wrapper
  button.asset
  .token
  .name-symbol
  .name {
  @apply font-black text-base;
}
.dashboard
  .dashboard-body
  .main-content
  .assets-wrapper
  button.asset
  .token
  .name-symbol
  .symbol {
  @apply font-black text-xs text-[#6C757D] dark:text-white;
}
.dashboard
  .dashboard-body
  .main-content
  .assets-wrapper
  button.asset
  .balance-price {
  @apply flex flex-col items-end gap-px;
}
.dashboard
  .dashboard-body
  .main-content
  .assets-wrapper
  button.asset
  .balance-price
  .balance {
  @apply font-black text-base text-[#343A40] dark:text-white;
}
.dashboard
  .dashboard-body
  .main-content
  .assets-wrapper
  button.asset
  .balance-price
  .price {
  @apply font-normal text-sm text-[#21BF73];
}
.dashboard .dashboard-body .main-content .activities-wrapper .sort-bar {
  @apply flex items-center justify-between text-[#6C757D] pt-5 pb-0 px-1.5;
}
.dashboard .dashboard-body .main-content .activities-wrapper .sort-bar > p {
  @apply font-semibold text-[10px] mb-0;
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .sort-bar
  .sort-by {
  @apply flex items-end text-[#6C757D];
  background: transparent;
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .sort-bar
  .sort-by
  > p {
  @apply font-semibold text-[10px] text-[#6C757D] mb-0;
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .sort-bar
  .sort-by
  img {
  @apply w-[19px] h-[19px];
}
.dashboard .dashboard-body .main-content .activities-wrapper .filter-bar {
  @apply mt-6;
}
.dashboard .dashboard-body .main-content .activities-wrapper .activities {
  @apply flex flex-col gap-2;
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .activities
  button.activity {
  @apply w-full h-[72px] flex justify-between shadow-[0px_2px_4px_rgba(0,0,0,0.075)] dark:shadow-[0px_2px_4px_rgba(128,128,128)] hover:dark:shadow-[0px_3px_7px_3px_rgba(128,128,128)] pl-3 pr-[22px] py-4 rounded-lg bg-white dark:bg-[#1B1B1B] dark:text-[#CDCDCD];
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .activities
  button.activity:hover {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .activities
  button.activity
  .token {
  @apply flex justify-between items-center gap-5;
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .activities
  button.activity
  .token
  .icon {
  @apply w-[33px] h-[33px];
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .activities
  button.activity
  .token
  .icon
  img {
  @apply w-full;
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .activities
  button.activity
  .token
  .icon
  p {
  @apply w-full h-full rounded-[50%];
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .activities
  button.activity
  .token
  .tx-time {
  @apply flex flex-col items-start gap-[3px];
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .activities
  button.activity
  .token
  .tx-time
  .tx {
  @apply font-light text-base text-[#212529] flex gap-[3px] text-[#D6DADE];
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .activities
  button.activity
  .token
  .tx-time
  .tx
  div {
  @apply font-black;
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .activities
  button.activity
  .token
  .tx-time
  .timestamp {
  @apply flex gap-2;
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .activities
  button.activity
  .token
  .tx-time
  .timestamp
  .time {
  @apply font-black text-xs text-[#6C757D];
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .activities
  button.activity
  .token
  .tx-time
  .timestamp
  .tx-type
  span {
  @apply font-extrabold text-[10px] leading-[14px] flex items-center justify-center text-white px-[6.6px] py-0.5 rounded-[20px];
  background: #dfe2e4;
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .activities
  button.activity
  .token
  .tx-time
  .timestamp
  .tx-type
  .saved {
  @apply text-black;
  background: #ffdd28;
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .activities
  button.activity
  .token
  .tx-time
  .timestamp
  .tx-type
  .protected {
  @apply text-[#EDF0F4];
  background: #43b649;
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .activities
  button.activity
  .token
  .tx-time
  .timestamp
  .tx-type
  .incomplete {
  @apply font-bold text-[#EDF0F4];
  background: #ef3429;
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .activities
  button.activity
  .token
  .tx-time
  .timestamp
  .tx-type
  .canceled {
  @apply text-black border border-solid border-black;
  background: #ffffff;
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .activities
  button.activity
  .balance-fee {
  @apply flex flex-col items-end gap-1;
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .activities
  button.activity
  .balance-fee
  .balance {
  @apply font-black text-base text-[#343A40] dark:text-white;
}
.dashboard
  .dashboard-body
  .main-content
  .activities-wrapper
  .activities
  button.activity
  .balance-fee
  .fee {
  @apply font-normal text-sm text-[#323232];
}
.dashboard .dashboard-body .main-content .how-to-use {
  @apply mt-[23px] mb-[26px] mx-0;
}
.dashboard .dashboard-body .main-content .how-to-use .how-to {
  @apply h-[66px] shadow-[0px_2px_4px_rgba(0,0,0,0.075)] flex justify-between pt-[18px] pb-[13px] px-[11px] rounded-lg;
  background: linear-gradient(96.56deg, #43b649 -1.26%, #024206 109%);
}
.dashboard .dashboard-body .main-content .how-to-use .how-to h3 {
  @apply font-extrabold text-xl text-white leading-[18px];
}
.dashboard .dashboard-body .main-content .how-to-use .how-to h3 .wider {
  @apply tracking-[1px];
}
.dashboard .dashboard-body .main-content .how-to-use .how-to button {
  @apply text-white font-semibold text-[10px];
  background: transparent;
}
.dashboard .footer {
  @apply h-[50px] w-[387px] flex items-center font-normal text-sm text-[#6A6A6A] justify-center shadow-[0px_-4px_10px_rgba(0,0,0,0.1)] dark:shadow-[0px_-6px_8px_rgba(255,255,255,0.25)] gap-[5px] rounded-[15px_15px_0_0] bottom-0;
  background: #fffefe;
}
.dashboard .footer a {
  @apply text-[#87A4D1] no-underline;
}

.resume-transaction-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10100 !important;
  transform: translate(-50%, -50%);
  width: 300px;
  height: auto;
  border-radius: 12px;
  outline: none;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
  background: rgb(255, 255, 255);
}