.wallet-select-wrapper {
  @apply w-full;
}
.wallet-select-wrapper button.title {
  @apply flex justify-between items-center w-full h-[41px] shadow-[0px_1px_2px_rgba(16,24,40,0.05)] border px-3.5 py-0 rounded-3xl border-solid border-[#D0D5DD] dark:bg-black dark:text-white;
  background: #ffffff;
}
.wallet-select-wrapper button.title .wallet-title {
  @apply flex items-center font-normal text-sm text-[#010101];
}
.wallet-select-wrapper button.title .wallet-title img {
  @apply w-4 h-auto mr-4;
}
.wallet-select-wrapper button.title .arrow {
  @apply text-base text-[#9D9D9D];
}
.wallet-select-wrapper button.title.active {
  background: linear-gradient(264.85deg, #43b649 30.84%, #005f33 93.78%);
}
.wallet-select-wrapper button.title.active .wallet-title {
  @apply text-white;
}
.wallet-select-wrapper .wallet-list {
  @apply w-full flex flex-col mt-2.5 rounded-lg max-h-[250px] overflow-y-scroll pr-2;
  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.2));
}
.wallet-select-wrapper .wallet-list button.title {
  @apply h-[52px] p-0 border-[none] dark:bg-black rounded-none;
}
.wallet-select-wrapper .wallet-list button.title .wallet-title {
  @apply w-full h-[52px] flex items-center font-normal text-xs px-3 py-0 dark:text-white;
}
.wallet-select-wrapper .wallet-list button.title .wallet-title img {
  @apply w-5 h-auto mr-4;
}
.wallet-select-wrapper .wallet-list button.title:hover {
  background: linear-gradient(
    96.31deg,
    #005f33 12.72%,
    #37a645 77.6%
  ) !important;
}
.wallet-select-wrapper .wallet-list button.title:hover span {
  @apply text-white;
}
.wallet-select-wrapper .wallet-list button:first-child {
  @apply rounded-[8px_8px_0_0];
}
.wallet-select-wrapper .wallet-list button:last-child {
  @apply rounded-[0_0_8px_8px];
}
.wallet-select-wrapper .wallet-alert {
  @apply font-bold text-[10px] text-[#FD0B0B] mt-4;
}
