.proccessing-success-modal {
    @apply absolute -translate-x-2/4 -translate-y-2/4 max-w-[500px] w-[calc(100%_-_36px)] shadow-[0px_20px_24px_-4px_rgba(16,24,40,0.6),0px_8px_8px_-4px_rgba(16,24,40,0.04)] rounded-xl left-2/4 top-2/4;
  }
  .proccessing-success-modal .proccessing-modal {
    @apply shadow-[0px_20px_24px_-4px_rgba(16,24,40,0.6),0px_8px_8px_-4px_rgba(16,24,40,0.04)] text-center pt-5 pb-[30px] px-10 rounded-xl;
    background: #ffffff;
  }
  .proccessing-success-modal .proccessing-modal img.logo {
    @apply absolute animate-[rotation_1.5s_infinite_linear] left-[30px] top-[30px];
  }
  @keyframes rotation {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(-359deg);
    }
  }
  .proccessing-success-modal .proccessing-modal .spinner-wrap {
    @apply relative w-[110px] flex justify-center items-center mx-auto my-0;
  }
  .proccessing-success-modal .success-modal {
    @apply shadow-[0px_20px_24px_-4px_rgba(16,24,40,0.6),0px_8px_8px_-4px_rgba(16,24,40,0.04)] text-[white] text-center pt-5 pb-[30px] px-[35px] rounded-xl;
    background: linear-gradient(95.91deg, #125526 8.64%, #37a645 89.12%);
  }
  .proccessing-success-modal .success-modal .title {
    @apply font-bold text-2xl mt-[15px];
    font-family: "Inter" !important;
  }
  .proccessing-success-modal .success-modal .des {
    @apply font-semibold text-sm leading-4 max-w-[340px] mt-[17px] mb-0 mx-auto;
    font-family: "Inter" !important;
  }
  .proccessing-success-modal .success-modal .info {
    @apply font-normal text-[10px] mt-4;
  }
  